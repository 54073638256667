import fetch from "./fetch";

const MMY02_01_URL = () => "/PC/WPC_MMY02_01";
const MMY02_02_URL = () => "/PC/WPC_MMY02_02";
const MMY02_021_URL = () => "/PC/WPC_MMY02_021";
const MMY02_03_URL = () => "/PC/WPC_MMY02_03";

export default {
  getMmy0201: fetch("GET", MMY02_01_URL),
  getMmy0202: fetch("GET", MMY02_02_URL),
  getMmy02021: fetch("GET", MMY02_021_URL),
  getMmy0203: fetch("GET", MMY02_03_URL),
};
