<template>
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">이전페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white">
					{{t('10276')}}
				</span>
			</p>
			<a class="nav__btn">
			</a>
		</div>
	</nav>
	<Loading v-if="booking === undefined" />
	<div id="container" class="container container--fit" v-else>
		<div id="sub" class="sub mypage">
			<section class="top top--fit">
				<h2 class="top__title">{{t('10277')}}</h2>
				<div class="top__date"> {{t('10278')}} : {{booking.BOOKING_NO}}</div>
			</section>
			<section class="content">
				<div class="box">
					<article class="booking-inner">
						<div class="reservation__info">
							<h3 class="reservation__title">{{booking.HOTEL_NM}}</h3>
							<div class="flex">
								<div class="top__type">
									<span class="top__badge grey1"> {{booking.PROD_TYPE}} </span>
									{{booking.PROD_NM}} <!-- 방 타입 -->
								</div>
							</div>
							<ul class="reservation__time">
								<li><span>{{t('10279')}}</span> {{booking.checkin}}</li>
								<li><span>{{t('10280')}}</span> {{booking.checkout}}</li>
							</ul>
						</div>
					</article>
					 <article class="booking-box booking-box--small" style="padding-bottom:0">
						<h3 class="booking-box__title">
							{{t('10281')}}
						</h3>
						<div class="price-list">
							<ul class="price-list__item">
								<li>
									<span>{{t('10282')}}</span>
									<span>{{booking.SALES_AMT.toLocaleString("en-US")}}원</span>
								</li>
								
								<li>
									<span>{{t('10283')}}</span>
									<span>-{{booking.DC_AMT_PT.toLocaleString("en-US")}}원</span>
								</li>
								<li>
									<span>{{t('10098')}}</span>
									<span>-{{booking.DC_AMT_CP.toLocaleString("en-US")}}원</span>
								</li>
								<li>
									<span>{{t('10100')}}</span>
									<span>-{{booking.DC_AMT_OT.toLocaleString("en-US")}}원</span>
								</li>
							</ul>
							<ul class="price-list__item" >
								<li>
									<span>
										{{t('10284')}} <br>
										<small>({{booking.PAY_TYPE}})</small>
									</span>
									<span>{{(booking.PAID_AMT - (booking.DC_AMT_PT + booking.DC_AMT_CP + booking.DC_AMT_OT)).toLocaleString("en-US")}}원</span>
								</li>
								<li>
									<span>{{t('10285')}}</span>
									<span v-if="cancel_data.length === 0">0원</span>
									<span v-else-if="cancel_data[0].CHECKIN_DIFF === 0">{{(booking.PAID_AMT - (booking.DC_AMT_PT + booking.DC_AMT_CP + booking.DC_AMT_OT)).toLocaleString("en-US")}}원</span>
									<span v-else>-{{((booking.PAID_AMT - (booking.DC_AMT_PT + booking.DC_AMT_CP + booking.DC_AMT_OT)) * ((cancel_data[0].REFUND_RATE).toFixed(0) / 100)).toLocaleString("en-US")}}원</span>
								</li>
							</ul>
						</div>
						<ul class="info">
							<li class="point point2">
								<h4 class="info__title">
									<strong class="black">{{t('10286')}}</strong>
								</h4>
								<div class="info__wrap">
									<div class="info__text" v-if="cancel_data.length === 0"><strong class="red">{{(booking.PAID_AMT - (booking.DC_AMT_PT + booking.DC_AMT_CP + booking.DC_AMT_OT)).toLocaleString("en-US")}}원</strong></div>
									<div class="info__text" v-else-if="cancel_data[0].CHECKIN_DIFF === 0"><strong class="red">0원</strong></div>
									<div class="info__text" v-else><strong class="red">{{((booking.PAID_AMT - (booking.DC_AMT_PT + booking.DC_AMT_CP + booking.DC_AMT_OT)) - ((booking.PAID_AMT - (booking.DC_AMT_PT + booking.DC_AMT_CP + booking.DC_AMT_OT)) * ((cancel_data[0].REFUND_RATE).toFixed(0) / 100))).toLocaleString("en-US")}}원</strong></div>
								</div>
							</li>
						</ul>
					</article> 
					<article>
						<div class="box box--reverse">
							<ul class="notice-list">
								<li v-html="booking.CANCEL_POLICY.split('\n').join('<br />')">
								</li>
							</ul>
							<div class="checkbox grey1">
								<label>
									<input type="checkbox" class="chk blind" v-model="check">
									<span class="checkbox__bg"></span>
									{{t('10287')}}
								</label>
							</div>
							<div>
								<div class="btns">
									<button v-if="cancel_data.length === 0" type="button" class="btn" :class="check ? 'btn-confirm' : 'btn-disabled'" @click="handleCancel" :disabled="!check">
										{{t('10288')}}
									</button>
									<button v-else v-bind="refund" type="button" class="btn" :class="check ? 'btn-confirm' : 'btn-disabled'" @click="handleCancel2" :disabled="!check">
										{{t('10288')}}
									</button>
								</div>
							</div>
						</div>
					</article>
				</div>
			</section>
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed, ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import dayjs from "dayjs";
import api from "@/urls/mmy02";
import "dayjs/locale/ko";
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'
import { onMounted } from '@vue/runtime-core';
import i18n from "@/i18n"; 


dayjs.locale("ko");

export default {
	setup() {
		const router = useRouter();
		const route = useRoute();
		const store = useStore();
		const check = ref(false);
	    const { t }= useI18n() //번역필수 모듈
		
		const userData = computed(() => store.state.userData);
		const cancel_data = computed(() =>{
			const finded = store.state.mmy02.mmy0202[route.params.id]?.[0];
			if(finded) {
				return store.state.mrm02.mrm02022[finded.HOTEL_NO]?.[finded.PROD_NO] ?? [];
			} else {
				return [];
			}
		});
		const booking = computed(() => {
			const finded = store.state.mmy02.mmy0202[route.params.id]?.find(item => item.SEQ === Number(route.query.seq));
			if(finded) {
				return {
					...finded,
					BOOKING_DATETIME: dayjs(finded.BOOKING_DATETIME).format("YY.MM.DD(dd) HH:mm"),
					checkin: dayjs(finded.SDATE).format("YYYY.MM.DD (dd)") + " " + finded.CHECKIN_TIME,
					checkout: finded.SDATE === finded.EDATE ?  finded.CHECKOUT_TIME : dayjs(finded.EDATE).format("YYYY.MM.DD (dd)") + " " + finded.CHECKOUT_TIME,
				}
			}else {
				return undefined;
			}
		});
		onMounted(() => {
			store.dispatch("mmy02/fetchMmy0202", { proc_cd: "01", mem_id: userData.value.mem_token, booking_no: route.params.id });
		})
		onMounted(() => {
			store.dispatch("mmy02/fetchMmy0202", { 
				proc_cd: "01", 
				mem_id: userData.value.mem_token, 
				booking_no: route.params.id 
			}).then(x=>{
				if(x.data.Res_data.length > 0) {
					const hotelNo = x.data.Res_data[0].HOTEL_NO;
					const prodNo = x.data.Res_data[0].PROD_NO;
					const SDATE = x.data.Res_data[0].SDATE;
					const PROC_CD = '01'
					store.dispatch("mrm02/fetchMrm02022", { hotelNo, prodNo, SDATE, PROC_CD });
				}
			});
		})
		return {
			booking,
			t,  //번역필수 모듈
		  i18n,
			cancel_data,
			goBack:() => {
				router.back();
			},
			check,
			handleCancel: async () => {
				store.dispatch("mmy02/fetchMmy0202", { 
				proc_cd: "01", 
				mem_id: userData.value.mem_token, 
				booking_no: route.params.id 
			}).then(x=>{
				if(x.data.Res_data.length === 1) {
					api.getMmy02021({ proc_cd: "01", mem_id: userData.value.mem_token, booking_no: route.params.id, booking_seq: booking.value.SEQ,process: "16"});
					router.replace("/mypage/booking/list?id=2")
				}else{
					api.getMmy02021({ proc_cd: "01", mem_id: userData.value.mem_token, booking_no: route.params.id, booking_seq: booking.value.SEQ,process: "13"});
				    router.replace("/mypage/booking/list?id=2")
				}
			})
					
			},
			handleCancel2: async () => {
				store.dispatch("mmy02/fetchMmy0202", { 
					proc_cd: "01", 
					mem_id: userData.value.mem_token, 
					booking_no: route.params.id 
				}).then(x=>{
					if(x.data.Res_data.length === 1) {
						api.getMmy02021({ proc_cd: "01", mem_id: userData.value.mem_token, booking_no: route.params.id, booking_seq: booking.value.SEQ,process: "12"});
						router.replace("/mypage/booking/list?id=2")
					}else{
						api.getMmy02021({ proc_cd: "01", mem_id: userData.value.mem_token, booking_no: route.params.id, booking_seq: booking.value.SEQ,process: "13"});
						router.replace("/mypage/booking/list?id=2")
					}
				})
			}
		};
	},
}
</script>